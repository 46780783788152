import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    
    constructor(private authService: AuthService) {
    }

    defaultMenu: IMenuItem[] = [
        {   
            name: 'Accueil',
            type: 'link',
            icon: 'i-Home1',
            state: '/'
        },
        {
            name: 'Référentiel',
            description: '',
            type: 'dropDown',
            icon: 'i-Library',
            sub: [
                { icon: 'i-File-Horizontal-Text', name: 'Article', state: '/referentiel/article', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Famille', state: '/referentiel/famille', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Unité', state: '/referentiel/unite', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Collaborateur', state: '/referentiel/collaborateur', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Fournisseur', state: '/referentiel/fournisseur', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Client', state: '/referentiel/client', type: 'link' }, 
                { icon: 'i-File-Horizontal-Text', name: 'Mode paiement', state: '/referentiel/mode-paiement', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Dépôt', state: '/referentiel/depot', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Tva', state: '/referentiel/tva', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Condition de paiement', state: '/referentiel/condition-paiement', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Pays', state: '/referentiel/pays', type: 'link' },
                { icon: 'i-File-Horizontal-Text', name: 'Ville', state: '/referentiel/ville', type: 'link' },
                { icon: 'i-Add-UserStar', name: 'Gestion des profils', state: 'administration/profil', type: 'link' }
            ]
        },
        {
            name: 'Stock',
            description: '',
            type: 'dropDown',
            icon: 'i-Big-Data',
            sub: [
                { icon: 'i-Add-File', name: 'Entrée', state: 'operations/stock/entree', type: 'link' },
                { icon: 'i-Remove-File', name: 'Sortie', state: 'operations/stock/sortie', type: 'link' },
                { icon: 'i-File-Copy', name: 'Transfert', state: 'operations/stock/transfert', type: 'link' },
                { icon: 'i-Check', name: 'Inventaire', state: 'operations/stock/inventaire', type: 'link' },
            ]
        },
        {
            name: 'Achat',
            description: '',
            type: 'dropDown',
            icon: 'i-Full-Basket',
            sub: [
                { icon: 'i-Receipt-4', name: 'Demande de prix', state: 'operations/achat/devis', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Bon de commande', state: 'operations/achat/bc', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Bon de réception', state: 'operations/achat/br', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Facture', state: 'operations/achat/fa', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Facture de retour', state: 'operations/achat/fr', type: 'link' },
                { icon: 'i-Receipt-4', name: "Facture d'avoir", state: 'operations/achat/faa', type: 'link' },
            ]
        },
        {
            name: 'Vente',
            description: '',
            type: 'dropDown',
            icon: 'i-Full-Cart',
            sub: [
                { icon: 'i-Receipt-4', name: 'Demande de prix', state: 'operations/vente/devis', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Bon de commande', state: 'operations/vente/bc', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Bon de livraison', state: 'operations/vente/bl', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Facture', state: 'operations/vente/fa', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Facture de retour', state: 'operations/vente/fr', type: 'link' },
                { icon: 'i-Receipt-4', name: "Facture d'avoir", state: 'operations/vente/faa', type: 'link' },
            ]
        },
        {
            name: 'Réglement',
            description: '',
            type: 'dropDown',
            icon: 'i-Money-2',
            sub: [
                { icon: 'i-Money-2', name: 'Réglement client', state: 'operations/reglement/client', type: 'link' },
                { icon: 'i-Money-2', name: 'Réglement fournisseur', state: 'operations/reglement/fournisseur', type: 'link' },
            ]
        }
    ];

    superAdministrationMenu = {
        name: 'Administration',
        description: '',
        type: 'dropDown',
        icon: 'i-Administrator',
        sub: [
            { icon: 'i-Business-Mens', name: 'Gestion des utilisateurs', state: 'administration/user', type: 'link' },
            { icon: 'i-Folders', name: 'Gestion des dossiers', state: 'administration/dossier', type: 'link' },
            { icon: 'i-Add-UserStar', name: 'Gestion des profils', state: 'administration/profil', type: 'link' },
            { icon: 'i-Add-UserStar', name: 'Administration des roles', state: 'administration/user-dossier', type: 'link' },
        ]
    };


    getMenuItems() {
        let menus = this.defaultMenu;
        if(this.authService.isSuperAdmin()) {

        } else if(this.authService.isAdmin()) {
            menus.push(this.superAdministrationMenu);
        }
        return menus;
    }

    menuItems = new BehaviorSubject<IMenuItem[]>(this.getMenuItems());
    menuItems$ = this.menuItems.asObservable();
}
